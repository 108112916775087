import { useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ActionButtonWide from "components/Page/ActionButtonWide";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/lab/PaginationItem";
import useTranslation from "hooks/useTranslation";
import Modal from "components/Modal";
import styles from "./styles.module.scss";
import ResultsGrid from "./ResultsGrid";
import WorkingHours from "./WorkingHours";

export const RESULTS_PER_PAGE = 6;

const PaginatedList = ({
  confirmQuestion,
  loading,
  noResultsHint,
  onSelect,
  results,
  paginationEnabled,
}) => {
  const t = useTranslation("universal.search_results");
  const [page, setPage] = useState(1);
  const [selectedResult, setSelectedResult] = useState(null);
  const showPagination = results.length > RESULTS_PER_PAGE;
  const showLoadMore = results.length > (RESULTS_PER_PAGE * page);
  const sliceStart = paginationEnabled ? (page - 1) * RESULTS_PER_PAGE : 0;
  const sliceEnd = RESULTS_PER_PAGE * page;

  const addressLine2 = useMemo(() => {
    if (!selectedResult) {
      return "";
    }

    const stateAndZip = [selectedResult.state, selectedResult.zip].filter((item) => !!item).join(" ");
    return [selectedResult.city, stateAndZip].filter((item) => !!item).join(", ");
  }, [selectedResult]);

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  if (!results.length) {
    return (
      (
        <Box display="flex" justifyContent="center" py={4}>
          <Typography variant="body1">{noResultsHint}</Typography>
        </Box>
      )
    );
  }

  return (
    <>
      <ResultsGrid onSelectedResult={setSelectedResult} results={results.slice(sliceStart, sliceEnd)} />
      {showPagination && paginationEnabled && (
        <Box className={styles.pagination} display="flex" justifyContent="center" py={4}>
          <Pagination
            count={Math.ceil(results.length / RESULTS_PER_PAGE)}
            onChange={(_, pageNumber) => setPage(pageNumber)}
            renderItem={(item) => <PaginationItem {...item} size="large" />}
            shape="rounded"
            variant="outlined"
          />
        </Box>
      )}
      {showLoadMore && !paginationEnabled && (
        <Box display="flex" justifyContent="center" py={2}>
          <ActionButtonWide
            endIcon={<ExpandMoreIcon />}
            onClick={handleLoadMore}
            sx={{ ".MuiButton-endIcon .MuiSvgIcon-root": { fontSize: 24 } }}
            variant="outlined"
          >
            {t("more_results")}
          </ActionButtonWide>
        </Box>
      )}
      <Modal
        cancelButtonText={t("cancel")}
        className={styles.modal}
        doneButtonText={t("confirm")}
        onCancelButtonClick={() => setSelectedResult(null)}
        onDoneButtonClick={() => onSelect(selectedResult)}
        open={!loading && !!selectedResult}
        shrink
        title={confirmQuestion}
      >
        <Box alignItems="center" display="flex" flexDirection="column" mb={2} textAlign="center">
          <Box py={2} textAlign="center">
            <Typography sx={{ fontWeight: "bold" }} variant="body1">{selectedResult?.title}</Typography>
          </Box>
          <Typography variant="body1">{selectedResult?.address}</Typography>
          <Typography variant="body1">{addressLine2}</Typography>
          <Typography variant="body1">{selectedResult?.phone}</Typography>
        </Box>
        {selectedResult?.workingHours && <Box py={2}><WorkingHours schedule={selectedResult?.workingHours} /></Box>}
      </Modal>
    </>
  );
};

export default PaginatedList;
