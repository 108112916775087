import SearchResults from "components/PCPAndPharmacy/SearchResults";
import t from "../../translate";

const SelectPharmacyScreen = ({
  isSubmitting,
  onEditSearch,
  onNotFound,
  onPharmacyConfirmed,
  pharmacies,
  searchParams,
  paginationEnabled,
}) => {
  const results = pharmacies.map(({ name, ...rest }) => ({ title: name, ...rest }));

  const handleSelect = (selectedResult) => onPharmacyConfirmed(
    selectedResult.id,
    selectedResult.ncpdpid,
    selectedResult.typeSource,
  );

  return (
    <SearchResults
      cantFindButtonText={t("cant_find_button_text")}
      confirmQuestion={t("confirm_question")}
      loading={isSubmitting}
      noResultsHint={t("no_results_hint")}
      onEdit={onEditSearch}
      onNotFound={onNotFound}
      onSelect={handleSelect}
      paginationEnabled={paginationEnabled}
      results={results}
      searchParams={{
        title: searchParams.name,
        zip: searchParams.zip,
        city: searchParams.city,
        state: searchParams.state,
      }}
      title={t("search_results_title")}
    />
  );
};

export default SelectPharmacyScreen;
