import { useEffect } from "react";
import { Formik } from "formik";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Page from "components/Page";
import SpacedGrid from "components/SpacedGrid";
import FormikTextField from "components/FormikControls/FormikTextField";
import FormikSelect from "components/FormikControls/FormikSelect";
import FormikZipField from "components/FormikControls/FormikZipField";
import FormikRadioButtonGroup from "components/FormikControls/FormikRadioButtonGroup";
import useTranslation from "hooks/useTranslation";
import getValidationSchema from "./getValidationSchema";
import { SIGNER_TYPE } from "./constants";

const SignOnBehalfForm = ({
  caregiverAttributes,
  onContinue,
  showAddress,
  title,
}) => {
  const t = useTranslation("universal.signature_form");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        relationship: "",
        reason: "",
        signerType: "",
        ...(showAddress && {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zip: "",
        }),
      }}
      onSubmit={(values) => {
        onContinue(values);
      }}
      validationSchema={getValidationSchema(showAddress, useTranslation("shared.validation"))}
    >
      {({ handleSubmit, values }) => (
        <Page>
          <Page.Layout columnConstraints={Page.DefaultColumnConstraints} />
          <Page.Form formik />
          <Page.Header hideLogoutButton />
          <Page.Title>{title || t("title")}</Page.Title>
          <Page.Content>
            <Box sx={{ pb: 3 }}>
              <FormikRadioButtonGroup
                name="signerType"
                options={caregiverAttributes.signerTypeOptions}
              />
            </Box>
            {values.signerType === SIGNER_TYPE.caregiver && (
              <>
                <SpacedGrid key="caregiverInfo">
                  <Grid item key="relationship" xs={12}>
                    <FormikSelect
                      label={t("relationship")}
                      name="relationship"
                      options={caregiverAttributes.relationshipOptions}
                      required
                    />
                  </Grid>
                  <Grid item key="firstName" xs={12}>
                    <FormikTextField
                      fullWidth
                      label={t("first_name")}
                      name="firstName"
                      required
                    />
                  </Grid>
                  <Grid item key="lastName" xs={12}>
                    <FormikTextField
                      fullWidth
                      label={t("last_name")}
                      name="lastName"
                      required
                    />
                  </Grid>
                  <Grid item key="reason" xs={12}>
                    <FormikSelect
                      label={t("reason")}
                      name="reason"
                      options={caregiverAttributes.reasonOptions}
                      required
                    />
                  </Grid>
                </SpacedGrid>
                {showAddress && (
                  <SpacedGrid key="caregiverAddress" sx={{ py: 4 }}>
                    <Grid item xs={12}>
                      <Typography color="textPrimary" component="p" variant="h6">
                        {t("address")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTextField fullWidth label={t("address_line_1")} name="addressLine1" required />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTextField fullWidth label={t("address_line_2")} name="addressLine2" />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTextField fullWidth label={t("city")} name="city" required />
                    </Grid>
                    <Grid item xs={6}>
                      <FormikSelect
                        emptyOption={t("select")}
                        fullWidth
                        label={t("state")}
                        name="state"
                        options={caregiverAttributes.stateOptions}
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormikZipField fullWidth label={t("zip_code")} name="zip" required />
                    </Grid>
                  </SpacedGrid>
                )}
              </>
            )}
          </Page.Content>
          <Page.ButtonPrimary onClick={handleSubmit}>{t("add_signature")}</Page.ButtonPrimary>
        </Page>
      )}
    </Formik>
  );
};

SignOnBehalfForm.defaultProps = {
  caregiverAttributes: {},
  caregiverSignatureEnabled: false,
};

export default SignOnBehalfForm;
