import * as Yup from "yup";
import getEnv from "utils/getEnv";
import { SIGNER_TYPE } from "./constants";

const getValidationSchema = (showAddress, t) => Yup.object().shape({
  signerType: Yup.string().required(),
  relationship: Yup.string()
    .when("signerType", {
      is: (signerType) => signerType === SIGNER_TYPE.caregiver,
      then: (schema) => schema.required(),
    }),
  firstName: Yup.string()
    .when("signerType", {
      is: (signerType) => signerType === SIGNER_TYPE.caregiver,
      then: (schema) => schema.required(),
    }),
  lastName: Yup.string()
    .when("signerType", {
      is: (signerType) => signerType === SIGNER_TYPE.caregiver,
      then: (schema) => schema.required(),
    }),
  reason: Yup.string()
    .when("signerType", {
      is: (signerType) => signerType === SIGNER_TYPE.caregiver,
      then: (schema) => schema.required(),
   }),
  ...(showAddress && {
    addressLine1: Yup.string()
      .when("signerType", {
        is: (signerType) => signerType === SIGNER_TYPE.caregiver,
        then: (schema) => schema.trim().required(),
      }),
    addressLine2: Yup.string(),
    city: Yup.string()
      .when("signerType", {
        is: (signerType) => signerType === SIGNER_TYPE.caregiver,
        then: (schema) => schema.trim().required(),
      }),
    state: Yup.string()
      .when("signerType", {
        is: (signerType) => signerType === SIGNER_TYPE.caregiver,
        then: (schema) => schema.trim().required(),
      }),
    zip: Yup.string()
      .when("signerType", {
        is: (signerType) => signerType === SIGNER_TYPE.caregiver,
        then: (schema) => schema.matches(
          getEnv("regexes", {}).zip_code_regex,
          t("error_invalid"),
        ).required(),
      }),
  }),
});

export default getValidationSchema;
