import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Page from "components/Page";
import initTranslationsHelper from "utils/initTranslationsHelper";

const translate = initTranslationsHelper("components.quick_confirm");

const QuickConfirm = ({
  children,
  disabled,
  icon,
  itemList,
  message,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonText,
  secondaryButtonText,
}) => {
  const { components: { QuickConfirm: { secondaryButtonVariant } } } = useTheme();

  return (
    <Page>
      <Page.Layout maintainTopSpacingWhenNoIcon />

      <Page.Icon>{icon}</Page.Icon>
      <Page.Title>{message}</Page.Title>
      <Page.Content disableGutters>{children || itemList}</Page.Content>

      <Page.ButtonPrimary disabled={disabled} onClick={onPrimaryButtonClick}>
        {primaryButtonText}
      </Page.ButtonPrimary>
      <Page.ButtonSecondary disabled={disabled} onClick={onSecondaryButtonClick} variant={secondaryButtonVariant}>
        {secondaryButtonText}
      </Page.ButtonSecondary>
    </Page>
  );
};

QuickConfirm.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  itemList: PropTypes.node,
  children: PropTypes.node,
  message: PropTypes.string.isRequired,
  onPrimaryButtonClick: PropTypes.func.isRequired,
  onSecondaryButtonClick: PropTypes.func.isRequired,
  primaryButtonText: PropTypes.string,
  secondaryButtonText: PropTypes.string.isRequired,
};

QuickConfirm.defaultProps = {
  disabled: undefined,
  icon: undefined,
  itemList: [],
  children: undefined,
  primaryButtonText: translate("no_change"),
};

export default QuickConfirm;
