import { useState } from "react";
import isNil from "lodash/isNil";
import requestToServer from "utils/requestToServer";
import SignatureForm from "previsit/SignatureForm";
import View from "./View";

const NJImmunizationConsent = ({
  action,
  data: {
    caregiverAttributes,
    caregiverSignatureEnabled,
    initialSignature,
    initialPatientImmunizationHistory,
  },
  method,
}) => {
  const [showSignatureForm, setShowSignatureForm] = useState(false);
  const [patientImmunizationHistory, setPatientImmunizationHistory] = useState(initialPatientImmunizationHistory || {});
  const [errors, setErrors] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const disabled = !isNil(initialPatientImmunizationHistory?.patientGivesNJIISConsent);
  const handleNJIISChange = (patientAnswers) => setPatientImmunizationHistory(patientAnswers);
  const handleSkip = () => handleSubmit({ skip: true });
  const handleSubmit = (values) => {
    setSubmitting(true);
    setErrors(null);

    requestToServer({
      body: { nj_immunization_consent: values },
      method,
      path: action,
    }).catch((backendErrors) => {
      setErrors(backendErrors);
    }).finally(() => {
      setSubmitting(false);
    });
  };

  const handleSave = (values) => {
    const consentValuesToSubmit = {
      country_of_birth: patientImmunizationHistory.countryOfBirth,
      name_of_primary_health_care_provider: patientImmunizationHistory.nameOfPrimaryHealthCareProvider,
      patient_gives_njiis_consent: patientImmunizationHistory.patientGivesNJIISConsent,
    };

    handleSubmit({ ...values, ...consentValuesToSubmit });
  };

  const handleContinue = () => {
    setShowSignatureForm(true);
  };

  return showSignatureForm ? (
    <SignatureForm
      caregiverAttributes={caregiverAttributes}
      caregiverSignatureEnabled={caregiverSignatureEnabled}
      initialDrawingInstructions={initialSignature}
      isSubmitting={isSubmitting}
      onSave={handleSave}
      showCaregiverSignatureAddress
    />
  ) : (
    <View
      disabled={disabled}
      errors={errors}
      isSubmitting={isSubmitting}
      onContinue={handleContinue}
      onNJIISChange={handleNJIISChange}
      onSkip={handleSkip}
      patientAnswer={patientImmunizationHistory}
    />
  );
};

export default NJImmunizationConsent;
