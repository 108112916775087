import * as Yup from "yup";
import zipSchema from "utils/yup/zipSchema";
import citySchema from "utils/yup/citySchema";
import { CITY_AND_STATE_VALUE, ZIP_VALUE } from "components/PCPAndPharmacy/constants";

const NAME_FIELD_MIN_LENGTH = 3;
const NAME_FIELD_MAX_LENGTH = 100;

const validationSchema = Yup.object().shape(
  {
    zip: zipSchema().when("locationSearchBy", (locationSearchBy, schema) => (
      locationSearchBy === ZIP_VALUE ? schema.required() : schema
    )),
    firstNameOrGroupName: Yup.string().when("lastName", {
      is: (lastName) => !lastName,
      then: Yup.string().min(NAME_FIELD_MIN_LENGTH).max(NAME_FIELD_MAX_LENGTH).required(),
      otherwise: Yup.string().min(NAME_FIELD_MIN_LENGTH).max(NAME_FIELD_MAX_LENGTH),
    }),
    lastName: Yup.string().when("firstNameOrGroupName", {
      is: (firstNameOrGroupName) => !firstNameOrGroupName,
      then: Yup.string().min(NAME_FIELD_MIN_LENGTH).max(NAME_FIELD_MAX_LENGTH).required(),
      otherwise: Yup.string().min(NAME_FIELD_MIN_LENGTH).max(NAME_FIELD_MAX_LENGTH),
    }),
    locationSearchBy: Yup.string().required(),
    city: citySchema().when("locationSearchBy", (locationSearchBy, schema) => (
      locationSearchBy === CITY_AND_STATE_VALUE ? schema.required() : schema
    )),
    state: Yup.string()
      .max(10)
      .when("locationSearchBy", (locationSearchBy, schema) => (
        locationSearchBy === CITY_AND_STATE_VALUE ? schema.required() : schema
      )),
  },
  [["firstNameOrGroupName", "lastName"]],
);

export default validationSchema;
