import { useState } from "react";
import requestToServer from "utils/requestToServer";
import QuickConfirmScreen from "./screens/QuickConfirmScreen";
import SearchPharmaciesScreen from "./screens/SearchPharmaciesScreen";
import SelectPharmacyScreen from "./screens/SelectPharmacyScreen";
import useLoadPharmacies from "./useLoadPharmacies";

const PreferredPharmacy = ({
  action,
  method,
  data: {
    searchPath, defaultPharmacy, stateOptions, paginationEnabled,
  },
}) => {
  const [screen, setScreen] = useState(
    defaultPharmacy ? QuickConfirmScreen.name : SearchPharmaciesScreen.name,
  );
  const [lastSearchParams, setLastSearchParams] = useState({
    name: "",
    locationSearchBy: "",
    zip: "",
    city: "",
    state: "",
  });
  const { loadPharmacies, loading, pharmacies } = useLoadPharmacies(searchPath, {
    onCompleted: () => setScreen(SelectPharmacyScreen.name),
  });
  const [isSubmitting, setSubmitting] = useState(false);

  const submit = (values) => {
    setSubmitting(true);
    requestToServer({ path: action, method, body: values });
  };

  const handleConfirm = () => submit({ quick_confirm: true });
  const skip = () => { submit({ skip: true }); };
  const handleNotFound = () => submit({ pharmacy_not_found: true });

  switch (screen) {
    case QuickConfirmScreen.name:
      return (
        <QuickConfirmScreen
          defaultPharmacy={defaultPharmacy}
          onNoChange={handleConfirm}
          onUpdate={() => setScreen(SearchPharmaciesScreen.name)}
        />
      );
    case SearchPharmaciesScreen.name:
      return (
        <SearchPharmaciesScreen
          initialSearchParams={lastSearchParams}
          loading={loading}
          onSearch={(searchParams) => {
            setLastSearchParams(searchParams);
            loadPharmacies(searchParams);
          }}
          onSkip={skip}
          stateOptions={stateOptions}
        />
      );
    case SelectPharmacyScreen.name:
      return (
        <SelectPharmacyScreen
          isSubmitting={isSubmitting}
          onEditSearch={() => {
            setScreen(SearchPharmaciesScreen.name);
          }}
          onNotFound={handleNotFound}
          onPharmacyConfirmed={(pharmacyId, ncpdpid, typeSource) => {
            submit({
              pharmacy_id: pharmacyId || "",
              ncpdp_id: ncpdpid || "",
              type_source: typeSource || "",
            });
          }}
          paginationEnabled={paginationEnabled}
          pharmacies={pharmacies}
          searchParams={lastSearchParams}
        />
      );
    default:
      return null; // Never reached, just to conform eslint
  }
};

export default PreferredPharmacy;
