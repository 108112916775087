function createSignaturePad(canvas, {
  displayOnly,
  onDraw,
  onDrawEnd,
}) {
  return $(canvas).signaturePad({
    drawOnly: true,
    displayOnly,
    lineWidth: false,
    penWidth: 3,
    penColour: "black",
    validateFields: false,
    canvas,
    onDraw,
    onDrawEnd,
  });
}

export default createSignaturePad;
