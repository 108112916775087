const UP_TO_FIRST_GROUP_INDEX = 3;
const UP_TO_SECOND_GROUP_INDEX = 6;

const PhoneTextField = ({
  TextFieldComponent, value, onChange, ...props
}) => (
  <TextFieldComponent
    onChange={(e) => {
      e.target.value = unmaskValue(e.target.value);
      onChange(e);
    }}
    value={maskValue(value)}
    {...props}
  />
);

const unmaskValue = (value) => value.replace(/-/g, "").slice(0, 10);

export const maskValue = (value) => {
  if (value === null || value === undefined) return "";

  const unprefixedValue = value.replace(/^\+1/, "");
  if (unprefixedValue.length <= UP_TO_FIRST_GROUP_INDEX) return unprefixedValue;
  if (unprefixedValue.length <= UP_TO_SECOND_GROUP_INDEX) {
    return [unprefixedValue.slice(0, UP_TO_FIRST_GROUP_INDEX), "-", unprefixedValue.slice(3)].join("");
  }
  return [
    unprefixedValue.slice(0, UP_TO_FIRST_GROUP_INDEX),
    "-",
    unprefixedValue.slice(UP_TO_FIRST_GROUP_INDEX, UP_TO_SECOND_GROUP_INDEX),
    "-",
    unprefixedValue.slice(UP_TO_SECOND_GROUP_INDEX),
  ].join("");
};

export default PhoneTextField;
