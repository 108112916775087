import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import RawHTML from "components/RawHTML";
import QuestionsList from "./QuestionsList";

const QuestionsSection = ({
 section, inputTypes, ...props
}) => (
  <>
    <Box m={2}>
      <Typography component="h2" variant="h6">
        {section.title}
      </Typography>
    </Box>
    {section.description && (
      <Box m={2}>
        <Typography variant="body1">
          <RawHTML>{section.description}</RawHTML>
        </Typography>
      </Box>
    )}
    <QuestionsList
      {...props}
      inputTypes={inputTypes}
      odd
    />
  </>
);

QuestionsSection.propTypes = {
  section: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
  inputTypes: PropTypes.shape({
    desktop: PropTypes.oneOf(["buttonGroup", "checkboxGroup", "number", "radio", "toggleQuestion"]),
    mobile: PropTypes.oneOf(["radio", "select"]),
  }),
};

QuestionsSection.defaultProps = {
  inputTypes: undefined,
};

export default QuestionsSection;
