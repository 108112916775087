import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import clsx from "clsx";
import FormField from "components/FormField";
import ScreenerInput from "./ScreenerInput";
import styles from "./styles.module.scss";

const ScreenerQuestion = ({
 question, onChange, value, error, readOnly, inputTypes,
}) => (
  <div
    className={clsx({
      [styles.error]: error,
      [styles.readOnly]: readOnly,
    })}
  >
    {question.groupTitle && (
      <Box mb={1}>
        <Typography component="span" style={{ fontWeight: "bold" }} variant="body1">
          {question.groupTitle}
        </Typography>
      </Box>
    )}

    <FormField
      disabled={readOnly}
      error={error}
      fullWidth
      label={question.label}
      required={question.required}
    >
      <ScreenerInput
        inputTypes={inputTypes}
        onChange={onChange}
        question={question}
        readOnly={readOnly}
        value={value}
      />
    </FormField>
  </div>
);

export default ScreenerQuestion;
