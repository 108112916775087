import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormField from "components/FormField";
import styles from "./styles.module.scss";

const ButtonGroup = ({
 error, onChange, question, readOnly, value, label, required,
}) => (
  <FormField
    error={error}
    fullWidth
    label={label}
    required={required}
  >
    <Box pt={1}>
      <div className={styles.question}>
        {question.options.map((option) => (
          <label className={styles.radioButton} key={option.value}>
            <input
              checked={value === option.value}
              className={styles.radioButtonInput}
              name={question.name}
              onChange={() => !readOnly && onChange(question.name, option)}
              type="radio"
              value={option.value}
            />
            <span className={styles.radioButtonText}>{option.label}</span>
          </label>
        ))}
      </div>
      {question?.edgeResponsesLabels && (
        <Box display="flex" justifyContent="space-between" mt={1}>
          {question.edgeResponsesLabels.map((edgeResponseLabel) => (
            <Typography key={edgeResponseLabel} variant="body2">
              {edgeResponseLabel}
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  </FormField>
);

export default ButtonGroup;
