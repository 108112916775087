import Typography from "@mui/material/Typography";
import QuickConfirm from "components/QuickConfirm";
import { useTheme } from "@mui/material/styles";
import { maskValue } from "components/PhoneTextField";
import t from "../../translate";

const QuickConfirmScreen = ({
 defaultPharmacy, onNoChange, onUpdate,
}) => {
  const theme = useTheme();

  return (
    <QuickConfirm
      icon={theme.icons.pharmacy}
      itemList={<PharmacyInfo defaultPharmacy={defaultPharmacy} />}
      message={t("is_pharmacy_up_to_date")}
      onPrimaryButtonClick={onNoChange}
      onSecondaryButtonClick={onUpdate}
      primaryButtonText={t("no_change")}
      secondaryButtonText={t("update")}
    />
  );
};

export default QuickConfirmScreen;

const PharmacyInfo = ({
  defaultPharmacy: {
    name, address, city, state, zip, phone,
  },
}) => {
  const lines = [name, address, `${city}, ${state} ${zip}`, maskValue(phone)];
  return lines.map((line) => (
    <Typography align="center" component="p" key={line} variant="body1">
      {line}
    </Typography>
  ));
};
