import { useState, useEffect } from "react";
import { trackEvent, eventNames } from "utils/EventsTracking";
import Page from "components/Page";
import useTranslation from "hooks/useTranslation";
import requestToServer from "utils/requestToServer";
import OneColumnGrid from "components/OneColumnGrid";
import EpionAuthorizationSignatureForm from "previsit/EpionAuthorization/SignatureForm";
import EpionAuthorizationModal from "previsit/EpionAuthorization/Modal";
import InfoPanel from "./InfoPanel";

const EpionAuthorizationV2 = ({
  action,
  method,
  data: {
    alreadySigned,
    epionAuthModalProperties,
  },
}) => {
  const [showSignatureView, setShowSignatureView] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const t = useTranslation("universal.epion_authorization");

  useEffect(() => {
    trackEvent(eventNames.EPION_AUTHORIZATION_PRESENTED);
  }, []);

  const handleAgree = () => {
    if (alreadySigned) {
      submitForm();
    } else {
      setShowSignatureView(true);
    }
  };

  const handleSkip = () => {
    trackEvent(eventNames.EPION_AUTHORIZATION_SKIPPED);
    submitForm({ skip_agreement: true });
  };

  const submitForm = (data = {}) => {
    if (!isSubmitting) {
      setSubmitting(true);
      requestToServer({ body: data, path: action, method });
    }
  };

  if (showSignatureView) {
    return (<EpionAuthorizationSignatureForm path={action} />);
  }

  return (
    <Page>
      <Page.Layout columnConstraints={Page.DefaultColumnConstraints} />
      {alreadySigned && <Page.Alert severity="info">{t("alert_text")}</Page.Alert>}
      <Page.Title>
        <OneColumnGrid>
          {t("personalized_offers_question")}
        </OneColumnGrid>
      </Page.Title>
      <Page.Subtitle>
        <span style={{ fontWeight: "600" }}>
          {t("optional")}
        </span>
      </Page.Subtitle>
      <Page.Content>
        <InfoPanel />
      </Page.Content>
      <Page.Note>
        <OneColumnGrid>
          {t("additional_info_1")}
          {" "}
          <EpionAuthorizationModal
            epionAuthModalProperties={epionAuthModalProperties}
            type="button"
          />
          {" "}
          {t("additional_info_2")}
        </OneColumnGrid>
      </Page.Note>
      <Page.ButtonPrimary disabled={isSubmitting} onClick={handleAgree}>
        {alreadySigned ? t("continue") : t("agree_and_sign")}
      </Page.ButtonPrimary>
      {!alreadySigned && (
        <Page.ButtonTertiary disabled={isSubmitting} loading={isSubmitting} onClick={handleSkip}>
          {t("skip")}
        </Page.ButtonTertiary>
      )}
    </Page>
  );
};

export default EpionAuthorizationV2;
