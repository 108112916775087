import base64ToHex from "utils/base64ToHex";
import getCardBrand from "./getCardBrand";

const readIDTechCardData = (inputData) => {
  /* eslint-disable-next-line no-console */
  console.log(`readIDTechCardData("${inputData}")`);

  const data = typeof (inputData) === "string" ? JSON.parse(inputData) : inputData;

  const cardData = {};
  const errors = [];

  const encodedFields = {
    ksn: "ksn",
    track1: "track-1-encrypted",
    track2: "track-2-encrypted",
  };

  const cardFirst4 = data["track-1"]?.slice(2, 6);
  const cardBrand = getCardBrand(cardFirst4);
  const cardLast4 = data["track-1"]?.match(/\*\*([0-9]{4,})\^/)?.[1];

  cardData.card_brand = cardBrand;
  cardData.card_last4 = cardLast4;

  Object.keys(encodedFields).forEach((key) => {
    const encodedValue = data[encodedFields[key]];
    try {
      const value = encodedValue ? base64ToHex(encodedValue) : undefined;
      cardData[key] = value;
    } catch (e) {
      errors.push(`field: ${key}, error: could not decode ${encodedValue}`);
    }
  });

  ["ksn", "track1"].forEach((key) => {
    if (!cardData[key]) {
      errors.push(`field: ${key}, error: missing value for ${key}`);
    }
  });

  cardData.device_serial_number = data["device-serial-number"];
  if (errors.length > 0) {
    window.Rollbar.error("ID Tech card read errors", { errors, deviceSerialNumber: cardData.device_serial_number });
  }

  return cardData;
};

export default readIDTechCardData;
