import { useEffect, useState } from "react";
import requestToServer from "utils/requestToServer";
import useScreenerState from "previsit/SimpleScreener/useScreenerState";

const ALWAYS_VISIBLE_SCORE_QUESTION = "rode_car_with_a_driver_who_was_high";

const useCrafftScreenerState = ({
  action,
  method,
  form,
  questions,
  questionsWithDays,
  readOnly,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [visibleQuestions, setVisibleQuestions] = useState(questions);
  const onSubmit = (responses) => {
    setSubmitting(true);
    const responsesToSave = visibleQuestions.reduce((responsesResult, question) => {
      responsesResult[question.name] = responses[question.name];
      return responsesResult;
    }, {});
    requestToServer({ path: action, method, body: { crafft_screener: responsesToSave } });
  };

  const {
    values,
    errors,
    handleChange: handleOptionChange,
    handleSubmit,
  } = useScreenerState({
    form,
    onSubmit,
    questions: visibleQuestions,
    readOnly,
  });

  useEffect(() => {
    setVisibleQuestions(filterQuestions(questions, questionsWithDays, form.initialValues || {}));
  }, []);

  const handleChange = (questionName, option) => {
    handleOptionChange(questionName, option);

    const updatedValues = { ...values, [questionName]: option.value };
    setVisibleQuestions(filterQuestions(questions, questionsWithDays, updatedValues));
  };

  return {
    values,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    visibleQuestions,
  };
};

const filterQuestions = (questions, questionsWithDays, answers) => {
  const showAllQuestions = anyQuestionWithDaysPositive(questionsWithDays, answers);

  if (showAllQuestions) {
    return questions;
  }

  const visibleQuestions = [...questionsWithDays, ALWAYS_VISIBLE_SCORE_QUESTION];
  return questions.filter((question) => visibleQuestions.includes(question.name));
};

const anyQuestionWithDaysPositive = (questionsWithDays, answers) =>
  questionsWithDays.map((questionName) => answers[questionName]).some((answer) => parseInt(answer, 10) > 0);

export default useCrafftScreenerState;
