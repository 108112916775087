import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import StripedList from "previsit/StripedList";
import ScreenerQuestion from "components/ScreenerQuestion";
import { questionsPropType } from "../propTypes";

const QuestionsList = ({
  errors,
  inputTypes,
  onChange,
  questions,
  readOnly,
  values,
  ...props
}) => (
  <StripedList {...props}>
    {questions.map((question) => (
      <Box data-testid="question-container" key={question.name} p={2}>
        <ScreenerQuestion
          error={errors[question.name]}
          inputTypes={inputTypes}
          onChange={onChange}
          question={question}
          readOnly={readOnly}
          value={values[question.name]}
        />
      </Box>
    ))}
  </StripedList>
);

QuestionsList.propTypes = {
  questions: questionsPropType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object,
  inputTypes: PropTypes.shape({
    desktop: PropTypes.oneOf(["buttonGroup", "checkboxGroup", "number", "radio", "toggleQuestion"]),
    mobile: PropTypes.oneOf(["radio", "select"]),
  }),
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object,
};

QuestionsList.defaultProps = {
  inputTypes: undefined,
  errors: {},
  readOnly: false,
  values: {},
};

export default QuestionsList;
