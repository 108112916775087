import { useEffect } from "react";
import { useLocation, HashRouter } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const withHashRouter = (Component, scrollToTop = true) => function (props) {
  return (
    <HashRouter>
      {scrollToTop && <ScrollToTop />}
      <Component {...props} />
    </HashRouter>
  );
};
