import useTranslation from "hooks/useTranslation";
import Screen from "components/screeners/Screen";
import useCrafftScreenerState from "./useCrafftScreenerState";

const CrafftScreener = ({
  action,
  method,
  data: {
    form,
    notes,
    questions,
    questionsWithDays,
    readOnly,
    sections,
  },
}) => {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    visibleQuestions,
  } = useCrafftScreenerState({
    action,
    method,
    form,
    questions,
    questionsWithDays,
    readOnly,
  });

  const t = useTranslation("universal.crafft_screener");

  return (
    <Screen
      description={t("description")}
      errors={errors}
      inputTypes={{ desktop: null, mobile: null }}
      isSubmitting={isSubmitting}
      notes={notes}
      onChange={handleChange}
      onSkip={false}
      onSubmit={handleSubmit}
      oneColumnLayout
      questions={visibleQuestions}
      readOnly={readOnly}
      sections={sections}
      title={t("title")}
      values={values}
    />
  );
};

export default CrafftScreener;
