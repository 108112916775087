import getCardBrand from "./getCardBrand";

const readMagTekCardData = (inputData) => {
  /* eslint-disable-next-line no-console */
  console.log(`readMagTekCardData("${inputData}")`);

  const data = typeof (inputData) === "string" ? JSON.parse(inputData) : inputData;

  const cardFirst6 = data["card-pan"]?.slice(0, 6);
  const cardBrand = getCardBrand(cardFirst6);
  const cardLast4 = data["card-last-4"];

  return {
    card_brand: cardBrand,
    card_last4: cardLast4,
    device_serial_number: data["device-serial-number"],
    ksn: data["device-ksn"],
    magneprint: data["encrypted-magneprint"],
    magneprint_status: data["magneprint-status"],
    track1: data["encrypted-track-1"],
    track2: data["encrypted-track-2"],
  };
};

export default readMagTekCardData;
