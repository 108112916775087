import useTranslation from "hooks/useTranslation";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import styles from "./styles.module.scss";

const formattedLocation = ({ city, state, zip }) => {
  const cityAndState = [city, state].filter((n) => n).join(", ");

  return [cityAndState, zip].filter((n) => n).join(" ");
};

const Result = ({ result, onSelectClick }) => {
  const t = useTranslation("universal.search_results");

  return (
    <Card
      data-testid="result"
      elevation={4}
      style={{
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box data-testid="result" key={result.id} p={1}>
        <Typography style={{ fontWeight: "bold" }} variant="body1">{result.title}</Typography>
        <Box py={2}>
          {result.distance && (
            <Typography variant="body2">
              {t("distance_in_miles", { distance: parseFloat(result.distance) })}
            </Typography>
          )}
        </Box>
        {result.address && <Typography variant="body2">{result.address}</Typography>}
        <Typography variant="body2">{formattedLocation(result)}</Typography>
        <Typography variant="body2">{result.phone}</Typography>
      </Box>
      <Box p={1}>
        <Link className={styles.select} component="button" onClick={onSelectClick} type="button">
          {t("select")}
        </Link>
      </Box>
    </Card>
  );
};

export default Result;
