import CurrencyTextField from "components/CurrencyTextField";
import RadioButtonGroup from "components/RadioButtonGroup";
import TextField from "components/TextField";
import ButtonGroup from "./ButtonGroup";
import CheckboxGroup from "./CheckboxGroup";
import ToggleQuestionGroup from "./ToggleQuestionGroup";

const Desktop = ({
  onChange, question, readOnly, type, value,
}) => {
  switch (type) {
    case "number":
      return (
        <TextField
          disabled={readOnly}
          fullWidth
          helperText={question.hint}
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
          name={question.name}
          onChange={(e) => !readOnly && onChange(question.name, { value: e.target.value })}
          type="number"
          value={value || ""}
        />
      );
    case "currency":
      return (
        <CurrencyTextField
          disabled={readOnly}
          fullWidth
          helperText={question.hint}
          name={question.name}
          onChange={(_e, amount) => !readOnly && onChange(question.name, { value: amount })}
          value={value || ""}
        />
      );
    case "radio":
      return (
        <RadioButtonGroup
          name={question.name}
          onChange={(e) => !readOnly && onChange(question.name, { value: e.target.value })}
          options={question.options}
          value={value || ""}
        />
      );
    case "toggleQuestion":
      return (
        <ToggleQuestionGroup
          onChange={onChange}
          question={question}
          readOnly={readOnly}
          value={value}
        />
      );
    case "checkboxGroup":
      return (
        <CheckboxGroup
          leftAlign
          onChange={onChange}
          question={question}
          readOnly={readOnly}
          value={value}
        />
      );
    case "buttonGroup":
    default:
      return (
        <ButtonGroup
          onChange={onChange}
          question={question}
          readOnly={readOnly}
          value={value}
        />
      );
  }
};

export default Desktop;
