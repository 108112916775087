import omit from "lodash/omit";
import "quill/dist/quill.snow.css";

window.Quill = require("quill");

export default function initFBTextEditor() {
  if (!window.fbControls) window.fbControls = [];
  window.fbControls.push((controlClass) => {
    class TextEditor extends controlClass {
      static get definition() {
        return {
          icon: "&#9780;",
          i18n: {
            default: "Consent Text",
          },
        };
      }

      /**
       * build a div DOM element to be later replaced with the quill editor
       * @return {Object} DOM Element to be injected into the form.
       */
      build() {
        const attrs = omit(this.config, ["value"]);
        this.field = this.markup("div", null, attrs);

        return this.field;
      }

      onRender() {
        const editor = {};
        editor.instance = new window.Quill(this.field, {
          modules: {
            toolbar: [
              [{ header: [1, 2, false] }],
              ["bold", "italic", "underline"],
              [{ list: "ordered" }, { list: "bullet" }],
            ],
          },
          placeholder: this.config.placeholder || "",
          theme: "snow",
        });
        this.setInitialValue(editor);

        const previewMode =
          $(this.field).closest(".textEditor-field").length === 0;
        if (previewMode) {
          $(this.field).closest(".formbuilder-textEditor")
            .html(editor.instance.root.innerHTML);
        } else {
          this.addTextChangeHandler(editor);
        }
      }

      setInitialValue(editor) {
        const Delta = window.Quill.import("delta");
        editor.data = new Delta();

        const value = this.config.value || "";
        if (value) {
          let valueToDisplay = "";
          try {
            valueToDisplay =
              JSON.parse(decodeURIComponent(escape(atob(value))));
          } catch (_e) {
            try {
              valueToDisplay = JSON.parse(atob(value));
            } catch (e) {
              console.error(e);
            }
          }

          editor.instance.setContents(valueToDisplay);
        }
      }

      addTextChangeHandler(editor) {
        editor.instance.on("text-change", (delta) => {
          editor.data = editor.data.compose(delta);
          const data = editor.instance.getContents();
          $(this.field).closest(".textEditor-field")
            .find(".fld-value")
            .val(btoa(unescape(encodeURIComponent(JSON.stringify(data)))));
        });
      }

      /**
       * extend the default events to add a prerender for textareas
       * @param {String} eventType
       * @return {Function} prerender function
       */
      on(eventType) {
        if (eventType === "prerender" && this.preview) {
          return (element) => {
            const target = this.field || element;
            /**
             * if this is a preview, stop events bubbling up so the editor
             * preview is clickable (and not draggable)
             */
            $(target).on("mousedown", (e) => {
              e.stopPropagation();
            });
          };
        }
        return super.on(eventType);
      }
    }

    controlClass.register("textEditor", TextEditor);
    return TextEditor;
  });
}
