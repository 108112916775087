import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import Modal from "components/Modal";
import Box from "@mui/material/Box";
import Log from "utils/logging";
import Page from "components/Page";
import Balance from "components/Balance";
import IconButton from "@mui/material/IconButton";
import Bold from "components/Typography/Bold";
import Configuration, { createConfigurationItem } from "components/Configuration";
import OneColumnGrid from "components/OneColumnGrid";
import PaymentAmountSelect from "components/PaymentAmountSelect";
import DetailsList from "components/payments/DetailsList";
import Container from "components/Container";
import RadioButtonGroup from "components/RadioButtonGroup";
import { useTheme, useMediaQuery, Typography } from "@mui/material";
import { trackEvent, eventNames } from "utils/EventsTracking";
import isNil from "lodash/isNil";
import PayIcon from "./PayIcon";
import t from "./translate";
import FixedFeeDetails from "./FixedFeeDetails";

const CASH = "cash";
const CARD = "card";

const SummaryRenderer = ({
  amountToPay,
  claims,
  collectionsBalance,
  config,
  copay,
  fixedFee,
  isSubmitting,
  minimumPaymentAmount,
  nextStepPath,
  onAmountToPayChange,
  onPaymentSkip,
  onPayWithCard,
  outstandingBalance,
  pageError,
  paymentCashTextLabel,
  paymentRequired,
  paymentSuccessful,
  paymentUnrecoverable,
  payWithCashLaterAllowed,
  selfPayFee,
  inOfficeCashPaymentEnabled,
  showOutstandingBalanceDetails,
  totalOwed,
}) => {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [cashModalOpen, setCashModalOpen] = useState(false);
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up("sm"));
  const paymentProcessed = paymentUnrecoverable || paymentSuccessful;
  const isAmountToPayValid = !isNil(amountToPay) &&
    (amountToPay > 0) &&
    (amountToPay >= minimumPaymentAmount) &&
    (amountToPay <= totalOwed);
  const totalIsPositive = totalOwed > 0;
  const canPayLess = totalOwed > minimumPaymentAmount;
  const allowedToPayLess = !fixedFee && !paymentProcessed && totalIsPositive && canPayLess;
  const paymentWithCard = (inOfficeCashPaymentEnabled && paymentMethod === CARD) || (!inOfficeCashPaymentEnabled);
  const showPaymentAmountSelect = paymentWithCard && allowedToPayLess;
  const showContinue = paymentProcessed || !totalIsPositive;
  const description = fixedFee ? t("description_fixed_fee") : t("description");
  const inOfficeCashPaymentOptions = useMemo(() => [
    {
      label: paymentCashTextLabel || t("cash_or_check"),
      value: CASH,
    },
    {
      label: t("credit_debit_card"),
      value: CARD,
    },
  ], [paymentCashTextLabel]);

  const handlePrimaryClick = () => {
    if (!isAmountToPayValid) {
      Log.error("Payment amount is not valid", { amountToPay, minimumPaymentAmount, totalOwed });
      return;
    }

    if (paymentMethod === CASH) {
      setCashModalOpen(true);
    } else {
      onPayWithCard();
    }
  };

  const handleHiddenPayWithCash = () => {
    trackEvent(eventNames.UNIVERSAL_PAYMENTS_PATIENT_PAID_OUTSIDE_OF_WORKFLOW);
    onPaymentSkip();
  };

  return (
    <Page>
      <Page.Title>{t("title")}</Page.Title>
      <Page.Subtitle>{description}</Page.Subtitle>

      {fixedFee && <Page.Icon>💳</Page.Icon>}

      {!isNil(pageError) && <Page.Alert severity="error" variant="standard">{pageError}</Page.Alert>}
      {paymentUnrecoverable && <Page.Alert severity="error">{t("payment_unrecoverable")}</Page.Alert>}
      {paymentSuccessful && <Page.Alert severity="warning">{t("payment_already_submitted")}</Page.Alert>}

      <Page.Content disableGutters>
        <OneColumnGrid>
          <Box display="flex" justifyContent="center" pb={2}>
            <Balance value={totalOwed} />
          </Box>
          {fixedFee ? (
            <FixedFeeDetails />
          ) : (
            <DetailsList
              claims={claims}
              collectionsBalance={collectionsBalance}
              copay={copay}
              outstandingBalance={outstandingBalance}
              selfPayFee={selfPayFee}
              showOutstandingBalanceDetails={showOutstandingBalanceDetails}
            />
          )}

          {inOfficeCashPaymentEnabled && (
            <Container display="flex" justifyContent={upSm ? "center" : undefined} mb={8} mt={5}>
              <RadioButtonGroup
                label={t("payment_method")}
                onChange={(e) => setPaymentMethod(e.target.value)}
                options={inOfficeCashPaymentOptions}
                required
                value={paymentMethod}
              />
            </Container>
          )}

          {showPaymentAmountSelect && (
            <Container display="flex" justifyContent={upSm ? "center" : undefined} mb={8} mt={5}>
              <PaymentAmountSelect
                isAmountToPayValid={isAmountToPayValid}
                max={totalOwed}
                min={minimumPaymentAmount}
                onChange={onAmountToPayChange}
                value={amountToPay}
              />
            </Container>
          )}

          <Modal
            doneButtonText={t("back")}
            onDoneButtonClick={() => setCashModalOpen(false)}
            open={cashModalOpen}
            shrink
            sx={{ textAlign: "center" }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <IconButton
                aria-label="paid"
                onClick={handleHiddenPayWithCash}
                sx={{ color: "var(--palette-primary-main)" }}
              >
                <PayIcon />
              </IconButton>
            </Box>
            <Typography><Bold>{paymentCashTextLabel || t("cash_or_check")}</Bold></Typography>
            <Typography>{t("complete_your_payment")}</Typography>
          </Modal>
        </OneColumnGrid>
      </Page.Content>

      {showContinue && (
        <Page.ButtonPrimary
          component={nextStepPath ? "a" : undefined}
          disabled={isSubmitting}
          href={nextStepPath}
          onClick={nextStepPath ? undefined : onPaymentSkip}
        >
          {t("continue")}
        </Page.ButtonPrimary>
      )}

      {!showContinue && inOfficeCashPaymentEnabled && !isNil(paymentMethod) && (
        <Page.ButtonPrimary disabled={isSubmitting} onClick={handlePrimaryClick}>
          {paymentMethod === CASH ? t("pay") : t("pay_button_text")}
        </Page.ButtonPrimary>
      )}

      {!showContinue && !inOfficeCashPaymentEnabled && ([
        <Page.ButtonPrimary disabled={isSubmitting} key="primary" onClick={handlePrimaryClick}>
          {t("pay_button_text")}
        </Page.ButtonPrimary>,

        payWithCashLaterAllowed && (
          <Page.ButtonSecondary disabled={isSubmitting} key="secondary" onClick={onPaymentSkip}>
            {t("pay_with_cash_check")}
          </Page.ButtonSecondary>
        ),

        config.additionalActions && <Page.Actions key="actions">{config.additionalActions.children}</Page.Actions>,

        !fixedFee && !paymentRequired && !payWithCashLaterAllowed && (
          <Page.ButtonTertiary disabled={isSubmitting} key="tertiary" onClick={onPaymentSkip}>
            {t("skip_button_text")}
          </Page.ButtonTertiary>
        ),
      ])}
    </Page>
  );
};

SummaryRenderer.propTypes = {
  amountToPay: PropTypes.number.isRequired,
  claims: PropTypes.arrayOf(
    PropTypes.shape({
      balance: PropTypes.number.isRequired,
      charges: PropTypes.arrayOf(
        PropTypes.shape({
          balance: PropTypes.number.isRequired,
          date: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          id: PropTypes.number.isRequired,
        }),
      ).isRequired,
      clean: PropTypes.bool.isRequired,
      date: PropTypes.string.isRequired,
      description: PropTypes.string,
      id: PropTypes.number.isRequired,
    }),
  ),
  collectionsBalance: PropTypes.number,
  config: PropTypes.shape({
    additionalActions: PropTypes.shape({
      children: PropTypes.node,
    }),
  }),
  copay: PropTypes.number,
  fixedFee: PropTypes.bool,
  inOfficeCashPaymentEnabled: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  minimumPaymentAmount: PropTypes.number,
  nextStepPath: PropTypes.string,
  onAmountToPayChange: PropTypes.func.isRequired,
  onPaymentSkip: PropTypes.func.isRequired,
  onPayWithCard: PropTypes.func.isRequired,
  outstandingBalance: PropTypes.number,
  pageError: PropTypes.string,
  paymentCashTextLabel: PropTypes.string,
  paymentRequired: PropTypes.bool,
  paymentSuccessful: PropTypes.bool,
  paymentUnrecoverable: PropTypes.bool,
  payWithCashLaterAllowed: PropTypes.bool,
  selfPayFee: PropTypes.number,
  showOutstandingBalanceDetails: PropTypes.bool,
  totalOwed: PropTypes.number.isRequired,
};

SummaryRenderer.defaultProps = {
  claims: [],
  collectionsBalance: null,
  config: {
    additionalActions: null,
  },
  copay: null,
  fixedFee: false,
  minimumPaymentAmount: 0,
  nextStepPath: null,
  outstandingBalance: null,
  pageError: null,
  paymentCashTextLabel: null,
  paymentRequired: false,
  paymentSuccessful: false,
  paymentUnrecoverable: false,
  payWithCashLaterAllowed: false,
  selfPayFee: null,
  showOutstandingBalanceDetails: false,
};

const Summary = ({ children, ...rest }) => (
  <Configuration renderer={SummaryRenderer} {...rest}>{children}</Configuration>
);
Summary.AdditionalActions = createConfigurationItem("additionalActions");
Summary.AdditionalActions.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Summary;
