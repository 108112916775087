const { useEffect } = require("react");

const useErrorFocus = ({
 errors, isSubmitting, isValidating, resolveErrorSelector,
}) => {
  useEffect(() => {
    const keys = Object.keys(errors);
    if (keys.length > 0 && isSubmitting && !isValidating) {
      const firstErrorField = keys[0];
      const selector = resolveErrorSelector(firstErrorField);
      // eslint-disable-next-line no-unused-expressions
      document.querySelector(selector)?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [errors, isSubmitting, isValidating, resolveErrorSelector]);
};

export default useErrorFocus;
