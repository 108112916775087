import requestToServer from "utils/requestToServer";

const createRetailTransaction = (path, { amount, cardData }, additionalData = {}) => requestToServer({
    path,
    method: "POST",
    body: {
      retail_transaction: {
        payment_amount: amount,
        encrypted_card_data: cardData,
        ...additionalData,
      },
    },
  });

export default createRetailTransaction;
