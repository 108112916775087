import { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Link from "@mui/material/Link";
import getEnv from "utils/getEnv";
import "utils/importAndInitJQuery";
import "jquery.signaturepad";
import useSize from "hooks/useSize";
import initTranslationsHelper from "utils/initTranslationsHelper";
import styles from "./styles.module.scss";
import createSignaturePad from "./createSignaturePad";

const SIGNATURE_DIMENTIONS = getEnv("signatureDimentions", { height: "250", width: "1064" });
const SIGNATURE_MIN_WIDTH = 300;
const t = initTranslationsHelper("universal.signature_form");

const SignaturePad = ({
  signature, displayOnly, onSignatureChange,
}) => {
  const canvasRef = useRef();
  const containerRef = useRef();
  const signaturePadRef = useRef();

  const [isEmpty, setIsEmpty] = useState(
    isSignatureEmpty(signature),
  );

  const { width } = useSize(containerRef);

  const showClearButton = !isEmpty && !displayOnly;

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.classList.add(styles.signaturePadBody);

    return () => document.body.classList.remove(styles.signaturePadBody);
  }, []);

  useEffect(() => {
    const handleDraw = () => {
      setIsEmpty(
        isSignatureEmpty(
          signaturePadRef.current.getSignature(),
        ),
      );
    };

    const handleDrawEnd = () => {
      onSignatureChange(
        signaturePadRef.current.getSignature(),
      );
    };

    const canvas = canvasRef.current;

    const signaturePad = createSignaturePad(canvas, {
      displayOnly,
      onDraw: handleDraw,
      onDrawEnd: handleDrawEnd,
    });

    if (signature.length) {
      signaturePad.regenerate(signature);
      setIsEmpty(isSignatureEmpty(signature));
    }
    signaturePadRef.current = signaturePad;
  }, [displayOnly, onSignatureChange, signature, width]);

  const handleClear = () => {
    const signaturePad = signaturePadRef.current;
    signaturePad.clearCanvas(false);

    const emptySignature = signaturePad.getSignature();
    onSignatureChange(emptySignature);
    setIsEmpty(isSignatureEmpty(emptySignature));

    signaturePad.regenerate([]);
  };

  return (
    <div
      style={{ margin: "0 auto", maxWidth: `${SIGNATURE_DIMENTIONS.width}px`, minWidth: `${SIGNATURE_MIN_WIDTH}px` }}
    >
      <div
        className={styles.root}
        ref={containerRef}
      >
        {isEmpty && (
          <span className={styles.emptyText}>
            {t("use_your_finger_to_start_writing")}
          </span>
        )}
        <canvas
          className={clsx(
            styles.canvas,
            {
              [styles.emptyCanvas]: isEmpty,
            },
          )}
          height={SIGNATURE_DIMENTIONS.height}
          ref={canvasRef}
          width={width}
        />
      </div>
      {showClearButton && (
        <Link component="button" onClick={handleClear} variant="body1">
          {t("clear")}
        </Link>
      )}
    </div>
  );
};

SignaturePad.propTyps = {
  // eslint-disable-next-line react/forbid-prop-types
  signature: PropTypes.array,
  displayOnly: PropTypes.bool,
  onSignatureChange: PropTypes.func,
  className: PropTypes.string,
};

SignaturePad.defaultProps = {
  signature: [],
  displayOnly: false,
  onSignatureChange: () => {},
  className: undefined,
};

export default SignaturePad;

function isSignatureEmpty(signature) {
  return signature.length === 0;
}
