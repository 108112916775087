import {
  useCallback, useEffect, useRef, useState,
} from "react";
import readIDTechCardData from "utils/readIDTechCardData";
import readMagTekCardData from "utils/readMagTekCardData";

export const SWIPE_DETECTION_TIMEOUT = (
  process.env.SWIPE_NOT_DETECTED_EVENT_TIMEOUT_MS || 15000
);

const useSwipeDetection = ({ onSwipeDetected, onSwipeNotDetected }) => {
  const [swipeStatus, setSwipeStatus] = useState({});
  const swipeNotDetectedTimer = useRef();

  const restartSwipeDetection = useCallback(() => setSwipeStatus({}), []);

  const handleReadMagTekCardData = useCallback((cardInputData, ipadId) => {
    setSwipeStatus({
      isDetected: true,
      ipadId,
      cardData: readMagTekCardData(cardInputData),
    });
  }, []);

  const handleReadIDTechCardData = useCallback((cardInputData, ipadId) => {
    setSwipeStatus({
      isDetected: true,
      ipadId,
      cardData: readIDTechCardData(cardInputData),
    });
  }, []);

  useEffect(() => {
    switch (swipeStatus.isDetected) {
      case undefined:
        window.stBrowserDidReadEncryptedCardData = handleReadMagTekCardData;
        window.stBrowserDidReadUMCardData = handleReadIDTechCardData;
        swipeNotDetectedTimer.current = setTimeout(
          () => setSwipeStatus({ isDetected: false }),
          SWIPE_DETECTION_TIMEOUT,
        );
        break;
      case true:
        onSwipeDetected(swipeStatus);
        clearTimeoutIfSet(swipeNotDetectedTimer);
        window.stBrowserDidReadEncryptedCardData = undefined;
        window.stBrowserDidReadUMCardData = undefined;
        break;
      case false:
        onSwipeNotDetected();
        clearTimeoutIfSet(swipeNotDetectedTimer);
        window.stBrowserDidReadEncryptedCardData = undefined;
        window.stBrowserDidReadUMCardData = undefined;
        break;
      default:
    }
  }, [swipeStatus]);

  useEffect(() => (
    () => clearTimeoutIfSet(swipeNotDetectedTimer)
  ), []);

  return restartSwipeDetection;
};

const clearTimeoutIfSet = (timerRef) => {
  if (timerRef.current) {
    clearTimeout(timerRef.current);
    timerRef.current = undefined;
  }
};

export default useSwipeDetection;
