import { useState } from "react";
import requestToServer from "utils/requestToServer";
import initTranslationsHelper from "utils/initTranslationsHelper";

const translate = initTranslationsHelper("universal.phq9_screeners");

const usePhq9ScreenerState = ({
  action,
  alwaysShowAllQuestions,
  difficultyFollowupQuestion,
  form,
  method,
  primaryQuestions,
  readOnly,
  screenerTypes,
  secondaryQuestions,
}) => {
  const allQuestions = [
    ...primaryQuestions,
    ...secondaryQuestions,
    difficultyFollowupQuestion,
  ];

  const getInitialAnswers = () => {
    const initialValues = form?.initialValues || {};

    return allQuestions
      .reduce((initialAnswers, question) => {
        initialAnswers[question.name] = question.options.find(
          (option) => option.value === initialValues[question.name],
        );

        return initialAnswers;
      }, {});
  };

  const [answers, setAnswers] = useState(getInitialAnswers());
  const [validate, setValidate] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const calculateScore = (questions) => {
    const userAnswers = getAnswersByQuestions(questions);

    return Object.keys(userAnswers).reduce((result, answerKey) => {
      if (answerKey === difficultyFollowupQuestion.name) {
        return result;
      }

      return result + (userAnswers[answerKey].score || 0);
    }, 0);
  };

  const getVisibleQuestions = () => {
    const primaryQuestionsScore = calculateScore(primaryQuestions);

    if (alwaysShowAllQuestions || primaryQuestionsScore > 2) {
      const secondaryQuestionsScore = calculateScore(secondaryQuestions);

      if ((primaryQuestionsScore + secondaryQuestionsScore) > 0) {
        return allQuestions;
      }

      return [...primaryQuestions, ...secondaryQuestions];
    }

    return [...primaryQuestions];
  };

  const getAnswersByQuestions = (questions) => (
    questions.reduce((result, question) => {
      result[question.name] = answers[question.name] || {};
      return result;
    }, {})
  );

  const getScreenerType = () => (
    visibleQuestions.length > 2 ? screenerTypes.phq9 : screenerTypes.phq2
  );

  const visibleQuestions = getVisibleQuestions();

  const onQuestionChange = (questionName, answer) => {
    setAnswers({ ...answers, [questionName]: answer });
  };

  const getError = (questionName) => {
    const missedAnswer = !answers[questionName];

    return validate && missedAnswer ? translate("error") : "";
  };

  const getValues = () => {
    const userAnswers = getAnswersByQuestions(visibleQuestions);

    return Object.keys(userAnswers).reduce((result, answerKey) => {
      result[answerKey] = userAnswers[answerKey].value;
      return result;
    }, {});
  };

  const values = getValues();

  const onSubmit = () => {
    if (isSubmitting) {
      return;
    }

    const hasEmptyAnswers = Object.values(values).some((answer) => !answer);

    if (hasEmptyAnswers && !readOnly) {
      setValidate(true);
    } else {
      setSubmitting(true);
      requestToServer({
        path: action,
        method,
        body: {
          phq9_screener: { ...values, screener_type: getScreenerType() },
        },
      });
    }
  };

  const errors = Object.fromEntries(visibleQuestions.map(({ name }) => [name, getError(name)]));

  return {
    visibleQuestions,
    onSubmit,
    errors,
    values,
    onQuestionChange,
  };
};

export default usePhq9ScreenerState;
